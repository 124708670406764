<template>
    <div class="repair-process">
        <!-- Content -->
        <transition
                name="fade"
                mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "RevisitIndex",
    }
</script>

<style scoped>

</style>